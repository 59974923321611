import styled from "styled-components";
import colors from "../colors";

const P1 = styled.p`
  font-size: 1em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.dark)};
  margin: 0.3em 0 0.8em;
  font-weight: 500;
  line-height: 1.8;
`;

export default P1;
