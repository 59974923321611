import React from "react";
import styled from "styled-components";
import FeedItem from "./FeedItem";
import useFetch from "../hooks/useFetch";
import P1 from "./type/P1";
import colors from "./colors";

const FeedWrapper = styled.div`
  position: sticky;
  background: ${colors.light_grey};
  top: 3.5em;
  flex: 0 1 auto;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 3.5em);
  transition: left 0.4s cubic-bezier(0.2, 0, 0.3, 1);
  border-left: 1px solid ${colors.grey}25;
  @media (min-width: 900px) {
    ${props =>
      !props.show &&
      `
      display: none;
    `}
  }
  @media (max-width: 900px) {
    position: fixed;
    left: 100%;
    width: 100%;
    background: white;
    z-index: 3;
    ${props =>
      props.show &&
      `
      left: 0;
    `}
  }
  > div {
    padding: 1.5em 1em 1.5em 1em;
    -webkit-overflow-scrolling: touch;
  }
`;

const Feed = props => {
  const { product, show } = props;
  const [feedItems, loading] = useFetch(`/feed/${product}`, [product]);

  const [config, loadingConfig] = useFetch("/config");

  if (loading || loadingConfig) {
    return (
      <FeedWrapper show={show}>
        <div>
          <P1 color="grey">Loading...</P1>
        </div>
      </FeedWrapper>
    );
  }

  const feedConfig =
    config.find(subConfig => subConfig.id === product).feed || {};

  const hiddenEventIds = feedConfig.hidden_events || [];
  const filteredFeedItems = feedItems.filter(
    item => !hiddenEventIds.includes(item.key)
  );

  return (
    <FeedWrapper show={show}>
      <div>
        {filteredFeedItems.map((feedItem, index) => (
          <FeedItem key={index} data={feedItem} />
        ))}
      </div>
    </FeedWrapper>
  );
};

export default Feed;
