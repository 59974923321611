import api from "../utils/api";
import { useState } from "react";
import { useEffect } from "react";

export default function useFetch(url, dependencies) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchUrl() {
    setLoading(true);
    const response = await api.get(url);
    setData(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || []);

  return [data, loading];
}
