import styled from "styled-components";
import colors from "../colors";

const H4 = styled.h4`
  font-size: 1.05em;
  color: ${props => (props.color ? colors[props.color] : colors.darker)};
  text-align: ${props => (props.center ? "center" : "left")};
  margin: 0 0 0.2em;
  font-weight: 500;
  line-height: 1.55;
  span {
    color: ${colors.ultra_dark};
  }
`;

export default H4;
