import moment from "moment";
import React from "react";
import styled from "styled-components";
import colors from "../components/colors";
import capitalize from "../utils/capitalize";
import valueFormatter from "../utils/valueFormatter";
import P1 from "./type/P1";
import P2 from "./type/P2";
import country_emojis from "../data/country_emojis";
import country_names from "../data/country_names";

const FeedString = styled(P1)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  word-break: break-all;
  flex-direction: column;
  span:first-child {
    font-size: 0.7em;
    line-height: 1.6;
    margin-top: 0.4em;
    text-transform: uppercase;
  }
  span:last-child {
    font-size: 0.85em;
    line-height: 1.4;
  }
  svg {
    width: 0.8em;
    position: relative;
    path {
      fill: ${colors.dark};
    }
  }
`;

const FeedItemWrapper = styled.div`
  margin-bottom: 0.6em;
  padding-bottom: 0.6em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  p {
    margin-top: 0;
    margin-left: 1em;
  }
`;

const ItemHeader = styled.div`
  display: grid;
  grid-template-columns: 1em 1fr;
  p {
    margin: 0 0 0.2em;
  }
`;

const ItemData = styled.div`
  display: flex;
  flex-flow: row wrap;
  > p {
    margin: 0 2em 0.5em 0;
  }
`;

const CommentSpan = styled.span`
  background: white;
  border-radius: 0 0.5em 0.5em 0.5em;
  color: ${colors.darker};
  padding: 0.5em;
  min-width: 90%;
`;

const keyOverrides = {
  lifetime_payment: {
    color: "#25a021",
    format: "EUR"
  },
  subscription_payment: {
    color: "#25a021",
    format: "EUR"
  },
  converted_from_free_trial: {
    color: "#25a021"
  },
  subscription_started: {
    color: "blue"
  },
  cancel_active_subscription: {
    color: "red"
  },
  cancel_free_trial: {
    color: "red"
  },
  subscription_refund_issued: {
    color: "red",
    format: "EUR"
  },
  subscription_payment_failed: {
    color: "red"
  },
  mrr: {
    format: "EUR"
  },
  arr: {
    format: "EUR"
  }
};

const dataItemsOder = {
  email: 1
};

const humanReadablePlans = {
  571437: "Yearly (No Trial)",
  540524: "Yearly (7 Days)",
  571439: "Yearly (14 Days)",
  550361: "Yearly (Teams)",
  539475: "Monthly (No Trial)"
};

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.color || colors.grey};
  margin-top: 0.6em;
  flex: 0 0 auto;
`;

const FeedItem = props => {
  const { time, key, value, data } = props.data;

  const customColor = keyOverrides[key] && keyOverrides[key].color;
  const formattedValue =
    keyOverrides[key] && keyOverrides[key].format
      ? valueFormatter(value, keyOverrides[key].format)
      : value;

  const formattedData =
    data &&
    (() => {
      if (data && data.plan) {
        data.plan_id = data.plan;
        data.plan = humanReadablePlans[data.plan];
      }

      if (data && data.subscription_plan_id) {
        data.plan_id = data.subscription_plan_id;
        data.subscription_plan_id =
          humanReadablePlans[data.subscription_plan_id];
      }

      const sortedDataKeys = Object.keys(data).sort((a, b) => {
        if (dataItemsOder[a] && dataItemsOder[b]) {
          // if both define order, use that
          return dataItemsOder[a] > dataItemsOder[b] ? -1 : 1;
        } else if (dataItemsOder[a]) {
          // if only a defines order, put it first
          return -1;
        } else if (dataItemsOder[b]) {
          // if only b defines order, put it first
          return 1;
        } else {
          // compare lexicographically
          return a - b;
        }
      });

      return (
        sortedDataKeys
          // filter out falsy data, except if the value is a boolean (we want to see it even if false)
          .filter(key => !!data[key] || typeof data[key] == "boolean")
          .map(key => (
            <FeedString
              key={key}
              style={{ minWidth: key === "comment" ? "100%" : 0 }}
            >
              {(() => {
                return <span style={{ flexShrink: 0 }}>{key}</span>;
              })()}
              {/* value */}
              {(() => {
                if (key === "country") {
                  return (
                    <span title={`${country_names[data[key]]} (${data[key]})`}>
                      {country_emojis[data[key]]}
                    </span>
                  );
                } else if (key === "comment") {
                  return <CommentSpan>{data[key]}</CommentSpan>;
                } else {
                  return (
                    <span style={{ color: colors.darker }}>{data[key]}</span>
                  );
                }
              })()}
            </FeedString>
          ))
      );
    })();

  const itemTime = (
    <P2 color="grey" title={moment(time).format()}>
      {moment(time).fromNow()}
    </P2>
  );

  return (
    <FeedItemWrapper>
      <ItemHeader>
        <Dot color={customColor} />
        <ItemData>
          <P1 style={{ color: customColor, marginBottom: ".1em" }}>
            {value !== 0 && (
              <span style={{ color: customColor }}>{formattedValue}</span>
            )}{" "}
            {capitalize(key)}
          </P1>
          {formattedData}
        </ItemData>
      </ItemHeader>
      {itemTime}
    </FeedItemWrapper>
  );
};

export default FeedItem;
