import React from "react";
import styled from "styled-components";
import colors from "./colors";
import icons from "../img/icons";

const StyledClose = styled(icons.Close)`
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  width: 1.6em;
  height: 1.6em;
  cursor: pointer;
  path {
    fill: ${colors.grey};
  }
`;

const PopupWrapper = styled.div`
  z-index: 99;
  position: fixed;
  padding: 2em;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => !props.show && `display: none; pointer-events: none;`}
`;

const PopupInner = styled.div`
  width: 100%;
  max-width: 40em;
  height: auto;
  box-sizing: border-box;
  max-height: 100%;
  min-height: 5em;
  overflow: scroll;
  position: relative;
  padding: 2em;
  background: ${colors.lighter};
  border-radius: 0.9em;
  box-shadow: 0px 1px 19px -4px rgba(0, 0, 0, 0.06),
    0px 5px 40px -5px rgba(0, 0, 0, 0.06);
  -webkit-overflow-scrolling: touch;
`;

const Popup = props => {
  const { show, onCloseClick } = props;

  return (
    <PopupWrapper show={show}>
      <PopupInner>
        <StyledClose onClick={() => onCloseClick()} />
        {props.children}
      </PopupInner>
    </PopupWrapper>
  );
};

export default Popup;
