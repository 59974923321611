import React from "react";

import styled from "styled-components";
import P1 from "./type/P1";
import H3 from "./type/H3";
import api from "../utils/api";
import { useState, useEffect } from "react";
import valueFormatter from "../utils/valueFormatter";

const StaticDataWrapper = styled.div`
  padding: 0.5em 0 1em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  grid-gap: 1em;
  transition: 0.4s opacity;
  ${props => props.isLoading && "opacity: .5"}
  h3, p {
    margin: 0;
  }
`;

const Item = styled.div`
  background: white;
  border-radius: 0.3em;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.07),
    0px 3px 10px -2px rgba(0, 0, 0, 0.05);
  padding: 1em;
  text-align: center;
`;

const StaticData = props => {
  const { currentProduct, values, dateRange, refresh } = props;

  const [loading, setLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const urls = values.map(v => v.url);
      const results = await Promise.all(urls.map(url => api.get(url)));
      setFetchedData(results.map(r => r.data));
      setLoading(false);
    }
    fetchData();
  }, [dateRange, refresh, currentProduct, values]);

  if (loading && !fetchedData) {
    return (
      <StaticDataWrapper isLoading={loading}>
        {values.map((value, index) => (
          <Item key={`${currentProduct}-${index}`}>
            <H3
              center
              color={
                value.format === "USD" || value.format === "EUR"
                  ? "green"
                  : "darker"
              }
            >
              ...
            </H3>
            <P1 center>{value.title}</P1>
          </Item>
        ))}
      </StaticDataWrapper>
    );
  }

  const items = values.map((value, index) => ({
    title: value.title,
    value: (fetchedData[index] && fetchedData[index].value) || 0,
    format: value.format
  }));

  return (
    <StaticDataWrapper isLoading={loading}>
      {items.map((item, index) => (
        <Item key={index}>
          <H3
            center
            color={
              item.format === "USD" || item.format === "EUR"
                ? "green"
                : "darker"
            }
          >
            {/* {currencies[item.currency]} */}
            {valueFormatter(item.value, item.format)}
          </H3>
          <P1 center>{item.title}</P1>
        </Item>
      ))}
    </StaticDataWrapper>
  );
};

export default StaticData;
