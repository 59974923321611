export default {
  white: "#FFFFFF",
  green: "#33CC4C",
  red: "#e84c85",
  transparent: "rgba(255,255,255,.6)",
  ultra_dark: "#32354C",
  darker: "#5D617A",
  dark: "#81849E",
  grey: "#B2B4C1",
  light_grey: "hsl(228, 33%, 92%)",
  light: "hsl(228, 33%, 96%)",
  lighter: "hsl(210, 37%, 98%)",
  accent1: "#3F56F8",
  accent2: "#B259F9",
  accent3: "#FF4689",
  unreadit: "#EF1B1B",
  accent1_gradient: "-35deg, #3F56F8 10%, #7E5EFF 80%",
  black_gradient: "-140deg, #000000 0%, #222222 90%"
};
