import kFormatter from "./kFormatter";

const valueFormatter = (value, format) => {
  let number = 0;

  if (value === null || value === undefined) {
    value = 0;
  }

  if (format === "USD") {
    number = value.toFixed(2);
    number = number.slice(
      0,
      number.toString().indexOf(".00") > -1
        ? number.toString().indexOf(".00")
        : 99
    );
    return `$${kFormatter(number, 9999)}`;
  } else if (format === "EUR") {
    number = value.toFixed(2);
    number = number.slice(
      0,
      number.toString().indexOf(".00") > -1
        ? number.toString().indexOf(".00")
        : 99
    );
    return `€${kFormatter(number, 9999)}`;
  } else if (format === "percent") {
    number = (value * 100).toFixed(1);
    number = number.slice(
      0,
      number.toString().indexOf(".0") > -1
        ? number.toString().indexOf(".0")
        : 99
    );
    return `${number}%`;
  } else {
    return value;
  }
};

export default valueFormatter;
