import moment from "moment";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import colors from "../components/colors";
import Header from "../components/Header";
import LayoutSection from "../components/LayoutSection";
import Popup from "../components/popup";
import H4 from "../components/type/H4";
import P2 from "../components/type/P2";
import api from "../utils/api";
import capitalize from "../utils/capitalize";

const MainLayout = styled.div`
  display: grid;
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const TasksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17.5em, 1fr));
  grid-gap: 0.8em;
  margin-top: 0.5em;
`;

const Task = styled.div`
  /* border: 1px solid orange; */
  border-radius: 0.3em;
  /* padding: 0.9em 1.3em; */
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.07),
    0px 3px 10px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  cursor: pointer;
  padding: 0.8em 1.2em;
  p {
    span {
      color: ${colors.darker};
    }
  }
  ${props => {
    switch (props.status) {
      case "running":
        return `border: 1px solid ${colors.dark};
      background: ${colors.dark}15;`;
      case "success":
        return `border: 1px solid ${colors.green};
      background: ${colors.green}15;`;
      case "failure":
        return `border: 1px solid ${colors.red};
      background: ${colors.red}15;`;
      default:
        break;
    }
  }}
`;

const LogItem = styled.div`
  border-bottom: 1px solid ${colors.light};
  padding-bottom: 0.5em;
  margin-bottom: 1em;
`;

const TasksPage = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [logPopupShown, setLogPopupShown] = useState(false);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskLog, setTaskLog] = useState(null);

  useEffect(() => {
    setInterval(() => {
      if (!localStorage.getItem("password")) {
        setLoggedOut(true);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    api.get("/tasks").then(res => {
      setTasks(res.data);
    });
    setInterval(() => {
      api.get("/tasks").then(res => {
        setTasks(res.data);
      });
    }, 10000);
  }, []);

  const showLog = id => {
    api.get(`/tasks/${id}/logs`).then(res => {
      if (res.data) {
        setTaskLog(res.data);
      }
      setLogPopupShown(true);
    });
  };

  if (loggedOut) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Popup show={logPopupShown} onCloseClick={() => setLogPopupShown(false)}>
        <H4 style={{ marginBottom: "0.7em" }}>{capitalize(taskTitle)}</H4>
        {taskLog &&
          taskLog.map((logItem, index) => (
            <LogItem key={index}>
              <P2 color="dark">
                Status:{" "}
                <span
                  style={{
                    color:
                      logItem.kind === "failure" ? colors.red : colors.green
                  }}
                >
                  {capitalize(logItem.kind)}
                </span>
              </P2>
              <P2>
                Time:{" "}
                <span>
                  {(logItem.time &&
                    capitalize(moment(logItem.time).fromNow())) ||
                    "none"}
                </span>
              </P2>
              <P2>{logItem.log !== "{}" && <pre>{logItem.log}</pre>}</P2>
            </LogItem>
          ))}
        {taskLog && taskLog.length === 0 && <P2>No logs found.</P2>}
      </Popup>
      <Header />
      <MainLayout>
        <LayoutSection width="100%">
          <TasksGrid>
            {tasks &&
              tasks.map(task => {
                return (
                  <Task
                    key={task.title}
                    status={task.last_status}
                    onClick={() => {
                      showLog(task.id);
                      setTaskTitle(task.title);
                    }}
                  >
                    <H4 style={{ marginBottom: ".4em" }}>
                      {capitalize(task.title)}
                    </H4>
                    <P2 color="dark">
                      Last started:{" "}
                      <span>
                        {capitalize(moment(task.last_started).fromNow())}
                      </span>
                    </P2>
                    <P2 color="dark">
                      Last completed:{" "}
                      <span>
                        {(task.last_completed &&
                          capitalize(moment(task.last_completed).fromNow())) ||
                          "none"}
                      </span>
                    </P2>
                    <P2 color="dark">
                      Running:{" "}
                      <span>
                        {(task.cron_human_readable &&
                          task.cron_human_readable) ||
                          "none"}
                      </span>
                    </P2>
                  </Task>
                );
              })}
          </TasksGrid>
        </LayoutSection>
      </MainLayout>
    </>
  );
};

export default TasksPage;
