import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import Chart from "../components/Chart";
import Feed from "../components/Feed";
import Header from "../components/Header";
import LayoutSection from "../components/LayoutSection";
import StaticData from "../components/StaticData";
import useLocalStorage from "../utils/useLocalStorage";
import P2 from "../components/type/P2";
import useFetch from "../hooks/useFetch";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const MainLayout = styled.div`
  ${props =>
    props.sidebarVisible
      ? `
    display: grid;
    grid-template-columns: 1fr 22em;
    `
      : `
    display: block;
  `}
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin: -0.5em;
`;

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function findTabs(productMetrics) {
  let graphsWithTab = productMetrics.graphs.filter(graph => graph.tab);
  return graphsWithTab
    .map(graph => {
      return graph.tab;
    })
    .filter(onlyUnique);
}

const IndexPage = props => {
  const { match } = props;

  const [config, loadingConfig] = useFetch("/config");

  const [sidebarVisible, setSidebarVisible] = useLocalStorage(
    "sidebar",
    "false"
  );
  const [refreshData, setRefreshData] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [loggedOut, setLoggedOut] = useState(false);
  const [redirectToProduct, setRedirectToProduct] = useState(null);
  useEffect(() => {
    setInterval(() => {
      if (!localStorage.getItem("password")) {
        setLoggedOut(true);
      }
    }, 1000);
  }, []);

  if (loadingConfig) {
    return null;
  }

  // read product from url
  const currentProduct = match.params.product;

  const products = config.map(metricsGroup => ({
    title: metricsGroup.product,
    slug: metricsGroup.id
  }));

  if (loggedOut) {
    return <Redirect to="/login" />;
  }

  if (redirectToProduct) {
    return <Redirect push to={`/${redirectToProduct}`} />;
  }

  const productMetrics = config.find(
    metricGroup => metricGroup.id === currentProduct
  );

  const tabs = findTabs(productMetrics);

  return (
    <>
      <Header
        chartsHeader
        products={products}
        currentProduct={currentProduct}
        onChangeCurrentProduct={product => setRedirectToProduct(product)}
        onSidebarToggleClick={() => {
          setSidebarVisible(!sidebarVisible);
        }}
        onRefreshClick={() => {
          setRefreshData(!refreshData);
        }}
        dateRange={dateRange}
        setDateRange={dateRange => setDateRange(dateRange)}
      />
      <MainLayout sidebarVisible={sidebarVisible}>
        <div style={{ width: "100%" }}>
          <LayoutSection
            width="100%"
            style={{ width: "100%" }}
            bgColor="light_grey"
          >
            <StaticData
              currentProduct={currentProduct}
              values={productMetrics.values}
              dateRange={dateRange}
              refresh={refreshData}
            />
          </LayoutSection>
          <Tabs defaultIndex={0} onSelect={index => console.log(index)}>
            <TabList>
              <Tab>All</Tab>
              {tabs.map((name, i) => (
                <Tab key={i}>{name}</Tab>
              ))}
              {!!productMetrics.graphs.find(graph => !graph.tab) && (
                <Tab>Uncategorized</Tab>
              )}
            </TabList>
            <LayoutSection width="100%" style={{ width: "100%" }}>
              <TabPanel>
                <Grid>
                  {productMetrics.graphs.map((metric, index) => (
                    <Chart
                      key={`${currentProduct}-${index}`}
                      project={metric.id}
                      metric={metric}
                      dateRange={dateRange}
                      refresh={refreshData}
                    />
                  ))}
                </Grid>
              </TabPanel>
              {tabs.map((tab, i) => {
                return (
                  <TabPanel key={i}>
                    <Grid>
                      {productMetrics.graphs
                        .filter(graph => graph.tab === tab)
                        .map((metric, index) => (
                          <Chart
                            key={`${currentProduct}-${index}`}
                            project={metric.id}
                            metric={metric}
                            dateRange={dateRange}
                            refresh={refreshData}
                          />
                        ))}
                    </Grid>
                  </TabPanel>
                );
              })}
              {!!productMetrics.graphs.find(graph => !graph.tab) && (
                <TabPanel>
                  <Grid>
                    {productMetrics.graphs
                      .filter(graph => !graph.tab)
                      .map((metric, index) => (
                        <Chart
                          key={`${currentProduct}-${index}`}
                          project={metric.id}
                          metric={metric}
                          dateRange={dateRange}
                          refresh={refreshData}
                        />
                      ))}
                  </Grid>
                </TabPanel>
              )}
            </LayoutSection>
          </Tabs>
        </div>
        <Feed product={currentProduct} show={sidebarVisible} />
      </MainLayout>
      <br />
      <P2
        center
        style={{ cursor: "pointer" }}
        onClick={() => localStorage.removeItem("password")}
      >
        Log Out
      </P2>
      <br />
    </>
  );
};

export default IndexPage;
