import React from "react";
import styled from "styled-components";
import colors from "./colors";

const LayoutSectionWrapper = styled.div`
  position: relative;
  /* z-index: 1; */
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
  background: ${props =>
    props.bgColor ? colors[props.bgColor] : "transparent"};

  > div {
    width: 74em;
    max-width: 100%;
    margin: 0 auto;
    padding-right: 1em;
    padding-left: 1em;
  }
  > div > img {
    max-width: 100%;
  }

  position: relative;

  padding-top: 0.7em;
  padding-bottom: 0.7em;
  display: inline-flex;
  min-height: ${props => props.height};

  :last-child {
    flex: 1 1 auto;
  }

  /* overflow: scroll; */
  /* -webkit-overflow-scrolling: touch; */
`;

const LayoutSection = props => {
  return (
    <LayoutSectionWrapper {...props}>
      <div style={{ width: props.width }}>{props.children}</div>
    </LayoutSectionWrapper>
  );
};

export default LayoutSection;
