import { ReactComponent as ToggleSvg } from "../img/icons/navigation.svg";
import { ReactComponent as RefreshSvg } from "../img/icons/refresh.svg";
import { ReactComponent as LogOutSvg } from "../img/icons/logout.svg";
import { ReactComponent as IncreaseSvg } from "../img/icons/increase.svg";
import { ReactComponent as DecreaseSvg } from "../img/icons/decrease.svg";
import { ReactComponent as UserSvg } from "../img/icons/user.svg";
import { ReactComponent as CloseSvg } from "../img/icons/close.svg";
import { ReactComponent as HomeSvg } from "../img/icons/home.svg";
import { ReactComponent as TasksSvg } from "../img/icons/tasks.svg";
import { ReactComponent as PreviousSvg } from "../img/icons/previous.svg";
import { ReactComponent as NextSvg } from "../img/icons/next.svg";
import { ReactComponent as FullscreenSvg } from "../img/icons/fullscreen.svg";
import { ReactComponent as FullscreenCloseSvg } from "../img/icons/fullscreenClose.svg";
import { ReactComponent as ConfigSvg } from "../img/icons/config.svg";
import colors from "../components/colors";
import styled from "styled-components";

const Toggle = styled(ToggleSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Refresh = styled(RefreshSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const LogOut = styled(LogOutSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Increase = styled(IncreaseSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Decrease = styled(DecreaseSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const User = styled(UserSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Close = styled(CloseSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Home = styled(HomeSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Tasks = styled(TasksSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Previous = styled(PreviousSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Next = styled(NextSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Fullscreen = styled(FullscreenSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const FullscreenClose = styled(FullscreenCloseSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;
const Config = styled(ConfigSvg)`
  ${props => (props.color ? `color: ${colors[props.color]}` : `color: white`)}
`;

const icons = {
  Home,
  Tasks,
  Toggle,
  Refresh,
  LogOut,
  Increase,
  Decrease,
  User,
  Close,
  Previous,
  Next,
  Fullscreen,
  FullscreenClose,
  Config
};

export default icons;
