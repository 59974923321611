import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import IndexPage from "./pages/index";
import LoginPage from "./pages/login";
import "./main.css";
import TasksPage from "./pages/tasks";
import { Helmet } from "react-helmet";
import ConfigPage from "./pages/config";

const App = () => {
  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          href="https://dashboard.superlinear.co/apple-touch-icon-precomposed.png"
        />
      </Helmet>
      <Router forceRefresh>
        <Switch>
          <Route
            path="/"
            exact
            component={() => <Redirect to="/typefully" />}
          />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/tasks" exact component={TasksPage} />
          <Route path="/config" exact component={ConfigPage} />
          <Route path="/:product" exact component={IndexPage} />
          <Route component={() => <p>404</p>} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
