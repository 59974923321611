import styled from "styled-components";
import colors from "../colors";

const H3 = styled.h3`
  font-size: 1.8em;
  color: ${props => (props.color ? colors[props.color] : colors.darker)};
  text-align: ${props => (props.center ? "center" : "left")};
  margin: 0;
  font-weight: 600;
  line-height: 1.55;
  span {
    color: ${colors.ultra_dark};
  }
`;

export default H3;
