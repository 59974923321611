import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../components/colors";
import Header from "../components/Header";
import LayoutSection from "../components/LayoutSection";
import P1 from "../components/type/P1";
import useLocalStorage from "../utils/useLocalStorage";

const Input = styled.input`
  border: none;
  border-radius: 0.3em;
  border: 1px solid ${colors.grey};
  padding: 0.4em 0.6em;
  font-size: 1em;
`;

const CenterLayour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledLink = styled(Link)`
  background: ${colors.accent1};
  border: none;
  border-radius: 0.3em;
  margin-top: 0.5em;
  padding: 0.4em 1.5em;
  font-size: 1em;
  color: white;
  text-decoration: none;
`;

const LoginPage = () => {
  const [password, setPassword] = useLocalStorage("password", "");

  return (
    <>
      <Header />
      <LayoutSection style={{ width: "100%", height: "100%" }}>
        <CenterLayour>
          <P1 style={{ margin: "0 0 .3em" }}>Insert password</P1>
          <Input
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
          />
          <StyledLink to="/" replace>
            Login
          </StyledLink>
        </CenterLayour>
      </LayoutSection>
    </>
  );
};

export default LoginPage;
