import styled from "styled-components";
import colors from "../colors";

const P2 = styled.p`
  font-size: 0.9em;
  text-align: ${props => (props.center ? "center" : "left")};
  color: ${props => (props.color ? colors[props.color] : colors.grey)};
  margin: 0.1em 0 0.3em;
  font-weight: 500;
  line-height: 1.8;
`;

export default P2;
