import axios from "axios";

const api = axios.create({
  baseURL: "https://metrics.superlinear.co/",
  timeout: 30000
});

api.interceptors.request.use(
  function(config) {
    config.headers["x-auth"] =
      localStorage.getItem("password") &&
      JSON.parse(localStorage.getItem("password"));
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem("password");
        window.location.href = `${window.location.origin}/login`;
      }
    }
    if (error.code === "ECONNABORTED") {
      alert("The server is not responding");
    }
    return Promise.reject(error);
  }
);

export default api;
