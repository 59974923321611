import React, { useState, useEffect } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import { css } from "emotion";
import api from "../utils/api";
import useFetch from "../hooks/useFetch";

const ConfigPage = props => {
  const [config, loadingConfig] = useFetch("/config");
  const [code, setCode] = useState(null);
  const [status, setStatus] = useState("");

  useEffect(() => {
    setCode(config && JSON.stringify(config, null, 2));
  }, [config]);

  if (loadingConfig) {
    return null;
  }

  const formatCode = () => {
    try {
      const json = JSON.parse(code);
      setCode(JSON.stringify(json, null, 2));
    } catch (err) {
      alert(err);
    }
  };

  const saveCode = () => {
    async function apiCall() {
      setStatus("saving");
      try {
        await api.post("/config", { config: code });
      } catch (err) {
        alert(err);
        console.log(err);
      }
      setStatus("");
    }

    apiCall();
  };

  return (
    <div
      className={css`
        padding-top: 40px;
      `}
    >
      <div
        className={css`
          position: fixed;
          top: 10px;
          right: 20px;
          z-index: 10;
        `}
      >
        <span>{status || "."}</span>{" "}
        <button onClick={formatCode}>format</button>{" "}
        <button onClick={saveCode}>save</button>
      </div>

      <Editor
        value={code}
        onValueChange={code => setCode(code)}
        highlight={code => highlight(code, languages.js)}
        padding={10}
        style={{
          fontFamily: "monospace",
          fontSize: 14
        }}
      />
    </div>
  );
};

export default ConfigPage;
