import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Funnel,
  FunnelChart,
  LabelList,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import styled from "styled-components";
import api from "../utils/api";
import valueFormatter from "../utils/valueFormatter";
import colors from "./colors";
import H3 from "./type/H3";
import H4 from "./type/H4";
import hsl from "../utils/hslToHex";
import icons from "../img/icons";
import P1 from "./type/P1";

const ChartWrapper = styled.div`
  flex: 1 1 24em;
  margin: 0.5em;
  background: ${colors.lighter};
  border-radius: 0.3em;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.07),
    0px 3px 10px -2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: 0.4s opacity;
  ${props => props.isLoading && "opacity: .5"}
  ${props =>
    props.columns &&
    `
    flex: 1 1 ${24 * props.columns}em
  `};
  .rechartsWrapper {
    user-select: none;
    padding: 0.8em 0.6em 1em 0.1em;
    height: 20em;
    @media (max-width: 700px) {
      height: 14em;
    }
  }

  .fullscreen-button {
    opacity: 0;
  }

  :hover {
    .fullscreen-button {
      opacity: 0.6;
    }
  }

  ${props =>
    props.fullscreen &&
    `
    position: fixed;
    z-index: 3;
    top: 1em;
    left: 1em;
    right: 1em;
    bottom: 1em;
    display: flex;
    flex-direction: column;
    > div {
      flex: 0 0 auto;
    }
    .rechartsWrapper {
      flex: 1 1 auto;
      height: auto;
      position: relative;
      .recharts-responsive-container {
        position: absolute;
        top: 1em;
        left: 1em;
        right: 1em;
        bottom: 1em;
        width: unset !important;
        height: unset !important;
      }
    }
  `}
`;

const Backdrop = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.darker};
  z-index: 2;
`;

const ChartHeaderWrapper = styled.div`
  background: white;
  border-bottom: 1px solid ${colors.light};
  padding: 0.8em 1.2em;
  display: flex;
  > div:first-child {
    flex: 1;
  }
  > div:last-child {
    text-align: right;
    align-self: flex-start;
  }
`;

const FullscreenButton = styled(icons.Fullscreen)`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;
const FullscreenCloseButton = styled(icons.FullscreenClose)`
  cursor: pointer;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
`;

const DiffGlyph = styled.span`
  width: 1.6em;
  height: 1.6em;
  background-color: ${props => `${colors[props.color]}20`};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5em;
  svg {
    width: 1.4em;
    height: 1.4em;
    path {
      fill: ${props => colors[props.color]};
    }
  }
`;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

function formatXAxis(tickItem) {
  const date = new Date(tickItem);
  const formattedDate = `${months[date.getMonth()].slice(
    0,
    3
  )}, ${date.getDate()}`;

  return formattedDate;
}

const margins = {
  top: 0,
  right: 0,
  left: 0,
  bottom: 0
};

const chartsColorsSolid = [
  hsl(0, 100, 60),
  hsl(30, 100, 60),
  hsl(50, 100, 60),
  hsl(100, 100, 60),
  hsl(180, 90, 60),
  hsl(220, 100, 60),
  hsl(260, 100, 60),
  hsl(300, 100, 60)
];

const chartsColorsSemiTransparent = chartsColorsSolid.map(
  color => color + "90"
);
const chartsColorsTransparent = chartsColorsSolid.map(color => color + "40");

const LoadingComponent = ({ title, columns, margins }) => (
  <ChartWrapper isLoading columns={columns}>
    <ChartHeaderWrapper>
      <div>
        <H4 style={{ margin: 0 }}>{title}</H4>
        <H3>...</H3>
      </div>
    </ChartHeaderWrapper>
    <div className="rechartsWrapper">
      <ResponsiveContainer height="100%" width="100%">
        <AreaChart data={[{ value: 0 }]} margin={margins}>
          <CartesianGrid strokeDasharray="3 3" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  </ChartWrapper>
);

const ChartDiff = ({ value, diff, negativeGood, format }) => {
  const color =
    (negativeGood && diff < 0) || (!negativeGood && diff > 0) ? "green" : "red";

  return (
    <P1
      title={valueFormatter(value, format)}
      color={color}
      style={{ fontWeight: 600, display: "flex", alignItems: "center" }}
    >
      <DiffGlyph color={color}>
        {diff > 0 ? <icons.Increase /> : <icons.Decrease />}
      </DiffGlyph>
      {diff.toString().replace("-", "")}%
    </P1>
  );
};

const ToogleFullScreenButton = ({ fullscreen, setFullscreen }) => {
  return fullscreen ? (
    <FullscreenCloseButton
      color="dark"
      onClick={() => setFullscreen(!fullscreen)}
      className="fullscreen-button"
    />
  ) : (
    <FullscreenButton
      color="dark"
      onClick={() => setFullscreen(!fullscreen)}
      className="fullscreen-button"
    />
  );
};

const ChartHeader = ({
  title,
  format,
  summary,
  diff,
  value,
  negativeGood,
  fullscreen,
  setFullscreen
}) => {
  return (
    <ChartHeaderWrapper>
      <div>
        <H4 style={{ margin: 0 }}>{title}</H4>
        <div style={{ display: "flex" }}>
          <H3
            color={format === "USD" || format === "EUR" ? "green" : "darker"}
            style={{ marginRight: ".3em" }}
          >
            {summary && valueFormatter(summary, format)}
          </H3>
        </div>
      </div>
      <div>
        {diff && (
          <ChartDiff
            diff={diff}
            value={value}
            format={format}
            negativeGood={negativeGood}
          />
        )}
        <ToogleFullScreenButton
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
      </div>
    </ChartHeaderWrapper>
  );
};

const BarGraph = ({ metric, dateRange, refresh }) => {
  const {
    title,
    urls,
    labels,
    columns,
    showDiff,
    negativeGood,
    stacked,
    summary,
    format
  } = metric;

  const [fullscreen, setFullscreen] = useState(false);

  const [loading, fetchedData, fetchedDataPrev] = useFetchedData(
    urls,
    dateRange,
    refresh,
    showDiff
  );

  if ((loading && !fetchedData) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const [
    mergedData,
    computedSummary,
    computedSummaryPrev,
    computedDiff
  ] = processData(fetchedData, fetchedDataPrev, labels, summary, showDiff);

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          format={format}
          summary={computedSummary}
          diff={computedDiff}
          value={computedSummaryPrev}
          negativeGood={negativeGood}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <BarChart data={mergedData} margin={margins}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis tickFormatter={value => valueFormatter(value, format)} />
              <Tooltip
                formatter={value => valueFormatter(value, format)}
                labelFormatter={label => moment(label).format("DD MMM YYYY")}
              />
              <Legend />
              {labels.map((label, index) => (
                <Bar
                  stackId={stacked ? "1" : undefined}
                  key={index}
                  dataKey={label}
                  fill={chartsColorsSolid[index]}
                  animationDuration={300}
                  isAnimationActive={false}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

const LineGraph = ({ metric, dateRange, refresh }) => {
  const {
    title,
    urls,
    labels,
    columns,
    zoomIn,
    showDiff,
    negativeGood,
    summary,
    format
  } = metric;

  const [fullscreen, setFullscreen] = useState(false);

  const [loading, fetchedData, fetchedDataPrev] = useFetchedData(
    urls,
    dateRange,
    refresh,
    showDiff
  );

  if ((loading && !fetchedData) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const [
    mergedData,
    computedSummary,
    computedSummaryPrev,
    computedDiff
  ] = processData(fetchedData, fetchedDataPrev, labels, summary, showDiff);

  const domain = zoomIn ? ["dataMin - 100", "dataMax + 50"] : [0, "auto"];

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          format={format}
          summary={computedSummary}
          diff={computedDiff}
          value={computedSummaryPrev}
          negativeGood={negativeGood}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <LineChart data={mergedData} margin={margins}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis
                tickFormatter={value => valueFormatter(value, format)}
                domain={domain}
              />
              <Tooltip
                formatter={value => valueFormatter(value, format)}
                labelFormatter={label => moment(label).format("DD MMM YYYY")}
              />
              <Legend />
              {labels.map((label, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={label}
                  stroke={colors.accent1}
                  strokeWidth={1.5}
                  activeDot={{ r: 8 }}
                  animationDuration={300}
                  isAnimationActive={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

const AreaGraph = ({ metric, dateRange, refresh }) => {
  const {
    title,
    urls,
    labels,
    columns,
    showDiff,
    negativeGood,
    stacked,
    summary,
    format
  } = metric;

  const [fullscreen, setFullscreen] = useState(false);

  const [loading, fetchedData, fetchedDataPrev] = useFetchedData(
    urls,
    dateRange,
    refresh,
    showDiff
  );

  if ((loading && !fetchedData) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const [
    mergedData,
    computedSummary,
    computedSummaryPrev,
    computedDiff
  ] = processData(fetchedData, fetchedDataPrev, labels, summary, showDiff);

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          format={format}
          summary={computedSummary}
          diff={computedDiff}
          value={computedSummaryPrev}
          negativeGood={negativeGood}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={mergedData} margin={margins}>
              <CartesianGrid strokeOpacity={0.2} />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis tickFormatter={value => valueFormatter(value, format)} />
              <Tooltip
                formatter={value => valueFormatter(value, format)}
                labelFormatter={label => moment(label).format("DD MMM YYYY")}
              />
              <Legend />
              {labels.map((label, index) => (
                <Area
                  key={index}
                  type="monotone"
                  stackId={stacked ? "1" : undefined}
                  dataKey={label}
                  stroke={chartsColorsSolid[index]}
                  strokeWidth={1}
                  fillOpacity={1}
                  animationDuration={300}
                  isAnimationActive={false}
                  fill={
                    stacked
                      ? chartsColorsSemiTransparent[index]
                      : chartsColorsTransparent[index]
                  }
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

const AutoLabeledAreaGraph = ({ metric, dateRange, refresh }) => {
  const { title, url, columns, label_overrides } = metric;

  const [fullscreen, setFullscreen] = useState(false);

  const [loading, data] = useFetchSimpleData(url, dateRange, refresh);

  if ((loading && !data) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const [mergedData, labels] = processAutoLabeledData(data, label_overrides);

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={mergedData} margin={margins}>
              <CartesianGrid strokeOpacity={0.2} />
              <XAxis dataKey="date" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip
                labelFormatter={label => moment(label).format("DD MMM YYYY")}
              />
              <Legend />
              {labels.map((label, index) => (
                <Area
                  key={index}
                  type="monotone"
                  stackId="1"
                  dataKey={label}
                  stroke={chartsColorsSolid[index]}
                  strokeWidth={1}
                  fillOpacity={1}
                  animationDuration={300}
                  isAnimationActive={false}
                  fill={chartsColorsSemiTransparent[index]}
                />
              ))}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

const PieGraph = ({ metric, dateRange, refresh }) => {
  const {
    title,
    urls,
    labels,
    columns,
    showDiff,
    negativeGood,
    summary,
    format
  } = metric;

  const [fullscreen, setFullscreen] = useState(false);

  const [loading, fetchedData, fetchedDataPrev] = useFetchedData(
    urls,
    dateRange,
    refresh,
    showDiff
  );

  if ((loading && !fetchedData) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const [
    mergedData,
    computedSummary,
    computedSummaryPrev,
    computedDiff
  ] = processData(fetchedData, fetchedDataPrev, labels, summary, showDiff);

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          format={format}
          summary={computedSummary}
          diff={computedDiff}
          value={computedSummaryPrev}
          negativeGood={negativeGood}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              {labels.map((label, index) => (
                <Pie
                  key={index}
                  data={mergedData}
                  dataKey={label}
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={"90%"}
                  fill="#8884d8"
                  isAnimationActive={false}
                >
                  {mergedData.map((entry, index) => (
                    <Cell key={index} fill={chartsColorsTransparent[index]} />
                  ))}
                </Pie>
              ))}
              <Tooltip
                formatter={value => valueFormatter(value, format)}
                labelFormatter={label => moment(label).format("DD MMM YYYY")}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

const FunnelGraph = ({ metric, dateRange, refresh }) => {
  const { title, url, columns } = metric;

  const [fullscreen, setFullscreen] = useState(false);
  const [loading, data] = useFetchSimpleData(url, dateRange, refresh);

  if ((loading && !data) || !dateRange) {
    return (
      <LoadingComponent title={title} columns={columns} margins={margins} />
    );
  }

  const getPercentage = value => {
    return `${Math.round(value * 100 * 10) / 10}%`;
  };

  const processedData = data.stages.map((value, index) => ({
    value,
    name:
      index === 0
        ? null
        : `${getPercentage(data.rel_conversions[index - 1])} (${getPercentage(
            data.abs_conversions[index - 1]
          )})`,
    fill: chartsColorsSolid[index]
  }));

  return (
    <>
      {fullscreen && (
        <Backdrop
          onClick={() => {
            setFullscreen(false);
          }}
        />
      )}
      <ChartWrapper
        isLoading={loading}
        columns={columns}
        fullscreen={fullscreen}
      >
        <ChartHeader
          title={title}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
        />
        <div className="rechartsWrapper">
          <ResponsiveContainer height="100%" width="100%">
            <FunnelChart>
              <Funnel
                label
                dataKey="value"
                data={processedData}
                isAnimationActive={false}
              >
                <LabelList position="right" offset={15} dataKey="name" />
              </Funnel>
            </FunnelChart>
          </ResponsiveContainer>
        </div>
      </ChartWrapper>
    </>
  );
};

function useFetchSimpleData(url, dateRange, refresh) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async function fetchData() {
      if (!dateRange) {
        return;
      }

      setLoading(true);

      const params = {
        start_date: moment(dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(dateRange.endDate).format("YYYY-MM-DD")
      };

      const res = await api.get(url, { params });

      setData(res.data);
      setLoading(false);
    })();
  }, [url, dateRange, refresh]);

  return [loading, data];
}

function useFetchedData(urls, dateRange, refresh, showDiff) {
  const [loading, setLoading] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);
  const [fetchedDataPrev, setFetchedDataPrev] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (!dateRange) {
        return;
      }

      const formattedDateRange = {
        start_date: moment(dateRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(dateRange.endDate).format("YYYY-MM-DD")
      };

      let diff = moment(dateRange.endDate).diff(
        moment(dateRange.startDate),
        "days"
      );

      const formattedDateRangePrev = {
        start_date: moment(dateRange.startDate)
          .subtract(diff, "days")
          .format("YYYY-MM-DD"),
        end_date: moment(dateRange.endDate)
          .subtract(diff, "days")
          .format("YYYY-MM-DD")
      };

      setLoading(true);
      const results = await Promise.all(
        urls.map(url =>
          api.get(url, {
            params: formattedDateRange
          })
        )
      );
      if (showDiff) {
        const resultsPrev = await Promise.all(
          urls.map(url =>
            api.get(url, {
              params: formattedDateRangePrev
            })
          )
        );
        setFetchedDataPrev(resultsPrev.map(r => r.data));
      }
      setFetchedData(results.map(r => r.data));

      setLoading(false);
    }

    fetchData();
  }, [urls, dateRange, refresh, showDiff]);

  return [loading, fetchedData, fetchedDataPrev];
}

function processData(data, dataPrev, labels, summary, showDiff) {
  // from {date, value} to {date, labelName} + flattening
  const labeledData = data
    .map((data, index) =>
      data.map(item => ({
        ...item,
        [labels[index]]: item.value,
        value: undefined
      }))
    )
    .flatMap(item => item);

  // {date: [{}, {}], ...}
  let dataGroupedByDate = {};

  labeledData.forEach(data => {
    if (dataGroupedByDate[data.date]) {
      dataGroupedByDate[data.date].push(data);
    } else {
      dataGroupedByDate[data.date] = [data];
    }
  });

  // array where each element is one object containing all the labels: {date, label1, label2, ...}
  let mergedData = [];

  for (const date in dataGroupedByDate) {
    const dataArray = dataGroupedByDate[date];
    const merged = Object.assign(...dataArray);
    mergedData.push(merged);
  }

  mergedData.sort((a, b) => (a.date > b.date ? 1 : -1));

  let flattenedValues = null;
  let computedSummary = null;

  let flattenedValuesPrev = null;
  let computedSummaryPrev = null;
  let computedDiff = null;

  if (summary) {
    switch (summary) {
      case "sum":
        computedSummary = data
          .flatMap(item => item)
          .reduce((total, item) => total + item.value, 0);
        break;
      case "average":
        flattenedValues = data.flatMap(item => item);
        computedSummary =
          flattenedValues.reduce((total, item) => total + item.value, 0) /
          flattenedValues.length;
        break;
      case "latest":
        flattenedValues = data.flatMap(item => item);
        computedSummary = flattenedValues[flattenedValues.length - 1].value;
        break;
      default:
        break;
    }
  }

  if (showDiff && summary) {
    switch (summary) {
      case "sum":
        computedSummaryPrev = dataPrev
          .flatMap(item => item)
          .reduce((total, item) => total + item.value, 0);
        computedDiff =
          parseInt((computedSummary / computedSummaryPrev) * 100 - 100) || null;
        break;
      case "average":
        flattenedValuesPrev = dataPrev.flatMap(item => item);
        computedSummaryPrev =
          flattenedValuesPrev.reduce((total, item) => total + item.value, 0) /
          flattenedValuesPrev.length;
        computedDiff = parseInt(
          (computedSummary / computedSummaryPrev) * 100 - 100
        );
        break;
      default:
        break;
    }
  }

  return [mergedData, computedSummary, computedSummaryPrev, computedDiff];
}

function processAutoLabeledData(data, label_overrides) {
  const labelOverrides = label_overrides || {};

  const labels = Array.from(
    new Set(data.map(el => labelOverrides[el.label] || el.label))
  ).sort();

  let dataGroupedByDate = {};

  data.forEach(data => {
    if (dataGroupedByDate[data.date]) {
      dataGroupedByDate[data.date].push(data);
    } else {
      dataGroupedByDate[data.date] = [data];
    }
  });

  // array where each element is one object containing all the labels: {date, label1, label2, ...}
  let mergedData = [];

  for (const date in dataGroupedByDate) {
    const dataArray = dataGroupedByDate[date].map(data => ({
      date: data.date,
      [labelOverrides[data.label] || data.label]: data.count
    }));
    const merged = Object.assign(...dataArray);
    mergedData.push(merged);
  }

  mergedData.sort((a, b) => (a.date > b.date ? 1 : -1));

  return [mergedData, labels];
}

const Chart = props => {
  const { metric, dateRange, refresh } = props;

  // prettier-ignore
  return {
    line: <LineGraph metric={metric} dateRange={dateRange} refresh={refresh} />,
    area: <AreaGraph metric={metric} dateRange={dateRange} refresh={refresh} />,
    auto_area: <AutoLabeledAreaGraph metric={metric} dateRange={dateRange} refresh={refresh} />,
    bar: <BarGraph metric={metric} dateRange={dateRange} refresh={refresh} />,
    pie: <PieGraph metric={metric} dateRange={dateRange} refresh={refresh} />,
    funnel: <FunnelGraph metric={metric} dateRange={dateRange} refresh={refresh} />
  }[metric.type];
};

export default Chart;
