import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import icons from "../img/icons";
import BoxySuiteIcon from "../img/products/boxysuite.png";
import UnreaditIcon from "../img/products/unreadit.png";
import MailbrewIcon from "../img/products/mailbrew.png";
import TypefullyIcon from "../img/products/typefully.png";
import useLocalStorage from "../utils/useLocalStorage";
import colors from "./colors";

const StyledDropdown = styled(Dropdown)`
  .Dropdown-control {
    cursor: pointer;
    border: none;
    border-radius: 0.4em;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    color: white;
    padding: 0 0.6em;
    height: 2.2em;
    font-size: 0.9em;
    width: 10em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5em;
    :active,
    :focus {
      background: rgba(0, 0, 0, 0.25);
      outline: none;
    }
    .Dropdown-arrow {
      border-color: rgba(255, 255, 255, 0.4) transparent transparent;
      border-width: 5px 4px 0;
    }
    .Dropdown-placeholder {
      opacity: 0.6;
    }
    .is-selected {
      opacity: 1;
    }
  }
  .Dropdown-menu {
    border: none;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
    margin-top: 0.4em;
    border-radius: 0.3em;
    max-height: 300px;
    overflow-y: auto;
    font-size: 0.9em;
  }
  .Dropdown-option:hover {
    background-color: ${colors.lighter};
  }

  .Dropdown-option.is-selected {
    color: ${colors.accent1};
    background-color: ${colors.lighter};
  }
`;

const HeaderSpacer = styled.div`
  height: 3.5em;
  @media (max-width: 800px) {
    height: 6em;
  }
`;

const ResponsiveWrapper = styled.div`
  @media (max-width: 800px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    > div:nth-child(2) {
      margin-top: 0.7em;
      order: 3;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const HeaderWrapper = styled.div`
  background: ${colors.accent1};
  position: relative;
  z-index: 2;
  flex: 0 0 auto;
  position: fixed;
  top: 0;
  width: 100%;

  > div {
    /* height: 3.5em; */
    /* width: 74em; */
    max-width: 100%;
    margin: 0 auto;
    padding: 0.7em 1.5em;
    @media (max-width: 640px) {
      padding-right: 1em;
      padding-left: 1em;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const MiddlePart = styled.div`
  display: flex;
  align-items: center;
`;

const DateRangeWrapper = styled.div`
  z-index: 2;
  input {
    cursor: pointer;
    border: none;
    border-radius: 0.4em;
    background: rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    color: white;
    padding: 0 0.6em;
    height: 2.2em;
    font-size: 0.9em;
    width: 6.5em;
    text-align: center;
    margin: 0 0.5em;
    :active,
    :focus {
      background: rgba(0, 0, 0, 0.25);
      outline: none;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
`;

const LinkWrapper = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  &.active {
    opacity: 1;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 1.1em;
    cursor: pointer;
  }
`;

const ProductLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    :hover {
      background: rgba(0, 0, 0, 0.1);
    }
    ${props =>
      props.active &&
      `
    background: rgba(0,0,0,.3) !important;
  `}
    margin-right: .9em;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    padding: 3px;
  }
`;

const LastButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-left: 1.1em;
    cursor: pointer;
  }
`;

const links = [
  {
    title: "Home",
    slug: "/",
    exact: true,
    icon: <icons.Home />
  },
  {
    title: "Tasks",
    slug: "/tasks",
    exact: false,
    icon: <icons.Tasks />
  }
];
const favoriteRanges = {
  "1 day": {
    startDate: moment().toDate(),
    endDate: moment().toDate()
  },
  "7 days": {
    startDate: moment()
      .subtract(7, "days")
      .toDate(),
    endDate: moment().toDate()
  },
  "14 days": {
    startDate: moment()
      .subtract(14, "days")
      .toDate(),
    endDate: moment().toDate()
  },
  "1 month": {
    startDate: moment()
      .subtract(1, "months")
      .toDate(),
    endDate: moment().toDate()
  },
  "3 months": {
    startDate: moment()
      .subtract(3, "months")
      .toDate(),
    endDate: moment().toDate()
  },
  "6 months": {
    startDate: moment()
      .subtract(6, "months")
      .toDate(),
    endDate: moment().toDate()
  },
  "1 year": {
    startDate: moment()
      .subtract(1, "years")
      .toDate(),
    endDate: moment().toDate()
  },
  "This Year": {
    startDate: moment()
      .startOf("year")
      .toDate(),
    endDate: moment().toDate()
  },
  "Since Launch": {
    startDate: moment(new Date("October 1 2018")).toDate(),
    endDate: moment().toDate()
  }
};

const productIcons = {
  "Boxy Suite": BoxySuiteIcon,
  Unreadit: UnreaditIcon,
  Mailbrew: MailbrewIcon,
  Typefully: TypefullyIcon
};

const Header = props => {
  const {
    products,
    currentProduct,
    onChangeCurrentProduct,
    onSidebarToggleClick,
    onRefreshClick,
    dateRange,
    setDateRange,
    chartsHeader
  } = props;

  const [selectedRange, setSelectedRange] = useLocalStorage("range", "7 days");
  const [isCustomRange, setIsCustomRange] = useState(false);

  let favorites = Object.keys(favoriteRanges);

  const handleChangeStart = date => {
    setDateRange({ ...dateRange, startDate: date });
    setIsCustomRange(true);
  };
  const handleChangeEnd = date => {
    setDateRange({ ...dateRange, endDate: date });
    setIsCustomRange(true);
  };

  let makePrevRange = () => {};
  let makeNextRange = () => {};

  if (dateRange) {
    let diff = moment(dateRange.endDate).diff(
      moment(dateRange.startDate),
      "days"
    );
    makePrevRange = () => {
      setIsCustomRange(true);
      setDateRange({
        startDate: moment(dateRange.startDate)
          .subtract(diff, "days")
          .toDate(),
        endDate: moment(dateRange.endDate)
          .subtract(diff, "days")
          .toDate()
      });
    };
    makeNextRange = () => {
      setIsCustomRange(true);
      setDateRange({
        startDate: moment(dateRange.startDate)
          .add(diff, "days")
          .toDate(),
        endDate: moment(dateRange.endDate)
          .add(diff, "days")
          .toDate()
      });
    };
  }

  useEffect(() => {
    if (chartsHeader && !isCustomRange) {
      setDateRange(favoriteRanges[selectedRange]);
      // console.log(`🚨 Setting date range to ${selectedRange}`);
    }
  }, [selectedRange, chartsHeader, setDateRange, isCustomRange]);

  const handleDateRangeDropdown = range => {
    setIsCustomRange(false);
    setSelectedRange(range.value);
    setDateRange(favoriteRanges[selectedRange]);
  };

  const handleClickProduct = productTitle => {
    console.log(`Clicked on ${productTitle}`);
    console.log("Products:");
    console.log(products);
    const product = products.find(p => p.title === productTitle);
    console.log("Found product:");
    console.log(product);
    onChangeCurrentProduct(product.slug);
  };

  const openConfigEditor = () => {
    window.open("/config", "Config Editor", "height=800,width=960");
  };

  // console.log("--------–-------------");
  // console.log("Selected range:");
  // console.log(selectedRange);
  // console.log("Favorites:");
  // console.log(favorites);
  // console.log("Start date:");
  // console.log(dateRange && dateRange.startDate);
  // console.log("End date:");
  // console.log(dateRange && dateRange.endDate);

  return (
    <HeaderSpacer>
      <HeaderWrapper>
        <ResponsiveWrapper>
          <Links>
            {links.map((link, index) => (
              <LinkWrapper
                key={index}
                to={link.slug}
                exact={link.exact}
                activeClassName="active"
              >
                {link.icon}
              </LinkWrapper>
            ))}

            {dateRange &&
              products.map((p, i) => {
                const active = p.slug === currentProduct;
                return (
                  <ProductLink
                    active={active}
                    key={i}
                    onClick={() => handleClickProduct(p.title)}
                  >
                    <img alt={p.title} src={productIcons[p.title]} />
                  </ProductLink>
                );
              })}
          </Links>
          <MiddlePart>
            {chartsHeader && dateRange && (
              <>
                <icons.Previous
                  style={{ cursor: "pointer" }}
                  onClick={() => makePrevRange()}
                />
                <StyledDropdown
                  options={favorites}
                  onChange={handleDateRangeDropdown}
                  value={isCustomRange ? null : selectedRange}
                  placeholder="Custom"
                />
                <DateRangeWrapper>
                  <DatePicker
                    dateFormat="d MMM yy"
                    selected={dateRange.startDate}
                    selectsStart
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={handleChangeStart}
                    locale="en"
                  />
                  <DatePicker
                    dateFormat="d MMM yy"
                    selected={dateRange.endDate}
                    selectsEnd
                    startDate={dateRange.startDate}
                    endDate={dateRange.endDate}
                    onChange={handleChangeEnd}
                    minDate={dateRange.startDate}
                    locale="en"
                  />
                </DateRangeWrapper>
                <icons.Next
                  style={{ cursor: "pointer" }}
                  onClick={() => makeNextRange()}
                />
              </>
            )}
          </MiddlePart>
          <LastButtons>
            {chartsHeader && dateRange && (
              <>
                <icons.Config onClick={openConfigEditor} />
                <icons.Refresh onClick={() => onRefreshClick()} />
                <icons.Toggle
                  onClick={() => {
                    onSidebarToggleClick();
                  }}
                />
              </>
            )}
          </LastButtons>
        </ResponsiveWrapper>
      </HeaderWrapper>
    </HeaderSpacer>
  );
};

export default Header;
